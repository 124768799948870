import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { Routes,Route} from "react-router-dom";
import Header from "./Header/header";
import Home from './Home/home';
import Product from "./Product/product";
import Login from './Login/login';
import Sign from './Sign/sign';
import Validusers from './Sign/validusers';
import Validmail from "./Sign/validmail";
import Forget from './Forget/forget';
import Fixpassword from "./Fixpassword/fixpassword";
import Privat from "./Privat/privat";
import UpdateName from "./UpdateName/updatename.js";
import Fixupdatename from "./Fixupdatename/fixupdatename.js";
import Create from "./Create/create";
import Validimg from "./Create/validimg";
import Testimg from "./Create/testimg";
import Delete from "./Delete/delete";
import Footer from './Footer/footer';
import False from './404/404';


function App() {
  const location = useLocation();
  const state = location.state;
  useEffect(()=>{
    window.scrollTo({
      top:0
    })
},[state]); 
  return (
    <div className="App">
         <header className="App-header">
         <Header/>
       </header>
      <main>
         <Routes >
              <Route path="/" element={<Home />}/>
              <Route path="/Product" element={<Product />}/>
              <Route path="/Login" element={<Login />}/>
              <Route path="/Sign" element={<Sign />}/>
              <Route path="/validusers" element={ <Validusers/>}/> 
              <Route path="/validmail" element={ <Validmail/>}/>
              <Route path="/Forget" element={<Forget />}/>
              <Route path="/privat" element={ <Privat/>}/> 
              <Route path="/updatename" element={ <UpdateName/>}/> 
              <Route path="/fixupdatename" element={ <Fixupdatename/>}/> 
              <Route path="/fixpassword" element={ <Fixpassword/>}/> 
              <Route path="/create" element={ <Create/>}/> 
              <Route path="/testimg" element={ <Testimg/>}/> 
              <Route path="/validimg" element={ <Validimg/>}/> 
              <Route path="/delete" element={ <Delete/>}/> 
              <Route path="*" element={ <False/>}/>
              </Routes>
   
              </main>
              <footer>
        <Footer/>
      </footer> 
              </div>
    
  )
}

export default App