import React,{ useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { createElement } from 'react';
import './product.css';
const Product = ((props) => {
    const location = useLocation();
    const state = location.state;
    const jsonData = { id: state.id };
    var visit="";
    switch (state.id) {
      case 'sefer_tora':
         visit="ספרי תורה";
        break;
      case "mezuzot":
        visit="מזוזות";
        break;
      case "pitum":
        visit="פיטום הקטורת";
        break;
      case "tefilin":
        visit="תפילין";
        break;
      case 'megilot':
        visit="מגילות";
        break;
      case "sgulot":
        visit="סגולות";
        break;
      case "product":
        visit="מוצרים נוספים";
        break;
    }
    const [backendData,setData]=useState();
    useEffect(()=>{
        fetchProduct()
    },[state]); 
const fetchProduct=async()=>{
    const res= await fetch("/product",{
        method: "POST",
         headers: {
             "content-type": "application/json"
         },
         body: JSON.stringify(jsonData),
     })
     .then((res) => res.json())
        .catch((error) => console.log(error));
        setData(res);
} 
    return (
        <table className="first">
         <thead>
          <tr>
            <th><h1>{visit}</h1></th>
          </tr>
        </thead> 
        <tbody>
            <tr className="tr">
              <td > 
                {(typeof backendData==="undefined")?(<p>loading...</p>):
                  (backendData.dataTable.map((a,i) => (<div key={i} id="divtd">
                  {createElement("img",{src:a.url,id:"idimg"})} 
                  {createElement("span",{id:"tdspan"},
                  //  `שם הסופר : הרב ${a.first_name} ${a.last_name} \n
                   `טלפון: ${a.phon}\n`
                    // עיר: ${a.city}\n
                   `גודל המוצר: ${a.size}\n`
                    // כמות מוצרים: ${a.cuont_produkt}\n
                    // סוג הכתב: ${a.tipe}\n
                  `מחיר: ${a.price} ש"ח \n
                    ${a.text_product}`,
                 )}
                  </div>)))}                   
                </td>
            </tr>  
        </tbody>
       </table>
    )
})
export default Product