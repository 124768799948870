import React,{ useEffect, useState } from "react";
import { createElement } from 'react';
function DeleteProduct() {
    const [backendData,setData]=useState();
    let reUseEfect=0;
    useEffect(()=>{
        fetchDelete();
    },[]); 
const fetchDelete=async()=>{
    const res= await fetch("/delete",{
        method: "POST" })
     .then((res) => res.json())
        .catch((error) => console.log(error));
        setData(res);
        }
        function deleteImg(product,id) {
            const formData = new FormData();
            formData.append("product", product);
            formData.append("id", id);
            alert("האם אתה בטוח במחיקת התמונה?");
            const jsonData = {};
            for (let [key, value] of formData) {
                jsonData[key] = value;
            }
            fetch("/delete-img", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body:JSON.stringify(jsonData)
                 }).then((res) => res.json())
                .then((res) => {
                    if (res.deletemysql) {
                        alert("התמונה נמחקה בהצלחה");
                        window.location="/delete";
                    } else {
                        alert("התמונה לא נמחקה בהצלחה")
                    }
                })
        } 
  return (
    <div className="first">
    <h1>מחיקת תמונה</h1>
  <table >
         <thead>
          <tr>
            <th><h2>נא לבחור תמונה שברצונך למחוק</h2></th>
          </tr>
        </thead> 
        <tbody>
            <tr>
              <td > 
                {(typeof backendData==="undefined")?(<p>loading...</p>):
                  (backendData.tableSql.map((a,i) => (<div key={i} id="divtd">
                  {createElement("img",{src:a.url,id:"idimg" ,onClick:()=>{
                    deleteImg(a.product,a.id_product)
                  }})} 
                  </div>)))}                   
                </td>
            </tr>  
        </tbody>
       </table>
    </div> 
 )
}

export default DeleteProduct