import React from 'react';
import './home.css';
import  {Link} from 'react-router-dom';
function Home() {

  return (
    <div className='main'>
      <div className='first-home'> 
  
        <h3>  מוצרי יודאיקה וסת"ם </h3>
        {/* <h1>גישה ישירה לסופרי הסת"ם</h1>
        <h4> מעוניינים בספר תורה איכותי שנכתב ע"י סופר מקצועי אמין וירא שמיים?
        <br/>מעוניינים לדעת מי כותב את המזוזה שאתם רוכשים לבית החדש שלכם?
        <br/> מרגישים חוסר בטחון לרכוש מוצרי סת"ם דרך חברת שיווק או אתר מסחרי?
        <br/>במאגר הענק של קהילת הסופרים תוכלו להתרשם מעבודות הסופרים, מהמלצות ואישורים 
        <br/>וליצור קשר ישיר עם הסופרים לקבלת מוצר אמין ואיכותי.</h4> */}
       </div>
       <div className="contener_a">
       <Link to="/product" state={{id:"sefer_tora"}} >
                <button className="b sifreitora" id="sifreitora">ספר תורה</button>
          </Link>
          <Link to="/product" state={{id:"mezuzot"}} >
                 <button className="b mezuzot" id='mezuzot'>מזוזה</button>
            </Link>
            <Link to="/product" state={{id:"tefilin"}} >
              <button className="b tefilin" id="tefilin">תפילין</button>
          </Link>
          <Link to="/product" state={{id:"megilot"}} >
                <button className="b megilot" id="megilot">מגילה</button>
            </Link> 
       <Link to="/product" state={{id:"product"}} >
                <button className="b product" id="product" >מוצרים נוספים</button>
           </Link>
        </div>
            <div className="contener_b"> 
            <Link to="/product" state={{id:"sefer_tora"}} >
            <button class="b sifreitora" id="sifreitora_b">ספר תורה</button>
            </Link>
            <Link to="/product" state={{id:"mezuzot"}} >
            <button class="b mezuzot" id="mezuzot_b">מזוזה</button>
            </Link> 
            <Link to="/product" state={{id:"tefilin"}} >
            <button class="b tefilin" id="tefilin_b">תפילין</button>
            </Link>           
            <Link to="/product" state={{id:"megilot"}} >
                <button class="b megilot" id="megilot">מגילה</button>
            </Link>
            <Link to="/product" state={{id:"pitum"}} >
            <button class="b pitum" id="pitum_b">פיטום הקטורת</button >
            </Link>
            {/* <Link to="/product" state={{id:"sgulot"}} >
            <button class="b sgulot" id="sgulot_b">סגולות</button>
            </Link>  */}
            <Link to="/product" state={{id:"product"}} >
            <button class="b product" id="product_b">מוצרים נוספים</button>
            </Link> 
            {/* <Link to="/sofrim" state={{id:"sofrim"}} >
            <button class="b sofrim" id="sofrim_b">סופרי סת"ם</button>
            </Link>
            <Link to="/sofrim" state={{id:"magia"}} >
            <button class="b magia" id="magia_b">בדיקות סת"ם</button>
            </Link>              */}
                {/*<div></div> <a href=''></a> <buttonclassName="b mezuzot" id="mezuzot_b">בתים לספר תורה</button>
                <div></div> <a href=''></a><button className="b mezuzot" id="mezuzot_b">מאמרים</button>
                <div></div> <a href=''></a><button className="b mezuzot" id="mezuzot_b">בתים למגילה</button>  */}
            </div>
    </div> 
  )
}

export default Home