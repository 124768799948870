import React from "react";
import "./sign.css";
function Sign() {
function handleSubmit(e){
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const jsonData = {};
    for (let [key, value] of data) {
        jsonData[key] = value;
    }
    fetch("/sign",
         {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(jsonData),
        }
    )
        .then((res) => res.json())
        .then((res) => {
            if (res.isToken) {
                alert("נרשמת בהצלחה! יש להכנס לדואר נכנס ולאשר את המייל.")
                window.location = "/";
            } else {
                alert("הרשמתך נכשלה. "+res.err)
            }
        })
}
  return (
    <div className='home-sign'>
       <h1>הרשמה לאתר</h1>
    {/* <h3>סופר יקר נא הכנס את פרטיך כפי שיופיעו באתר לצורך הקשר עם הלקוחות</h3> */}
    <form id="f" method="POST"  onSubmit={handleSubmit}>
        <br/><label >שם פרטי
        <br/><input type="text" name="first_name" id="first_name"/></label>  
        <br/><label >שם משפחה
        <br/><input type="text" name="last_name" id="last_name"/></label>
        <br/><label >אימייל
        <br/><input type="email" name="email" id="email"/></label>
        <br/><label >טלפון
        <br/><input type="text" name="phone"id="phone"/></label> 
        {/* <br/><label >עיר
        <br/><input type="text" name="city" id="city"/></label> */}
        <br/><label >סיסמא מינימום 6 תווים
        <br/><input type="password" name="password" id="password" minlength="6" maxlength="14"/></label>
        <br/><label >אימות סיסמא
        <br/><input type="password" name="valid_password" id="valid_password"/></label>
        <br/><br/><input type="submit" value="שליחה"/>
    </form>
    </div>
  )
}

export default Sign