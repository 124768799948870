import React from 'react'

function fixpassword() {
    function handleSubmit(e){
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const jsonData = {};
        for (let [key, value] of data) {
            jsonData[key] = value;
        }
        const params=window.location.href.split("?");
        params.push(jsonData);
        fetch("/fixpassword",
             {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(params),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if(res.isFix){
                    alert("הסיסמה שונתה בהצלחה!")
                  window.location="/login";
                 } else {
                    alert(res.err)
                }
            })
    }
      return (
        <div class="first">                
        <h1>סיסמא חדשה</h1>
        <form id="f" method="POST"  onSubmit={handleSubmit}>
            <label>הכנס סיסמה חדשה
            <br/><input type="password" name="pass1"/></label>
            <br/><label>הכנס שוב סיסמה חדשה
            <br/><input type="password" name="pass2"/></label> 
            <br/><br/><input type="submit" value="שליחה"/> 
             </form>
        </div> 
         )
}

export default fixpassword