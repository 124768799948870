import React from 'react'
import "./privat.css";
function privat() {
  return (
    <div className="first">
    <h1>איזור האישי</h1>
            <h3> כאן ניתן להוסיף או להסיר מוצר</h3>
            <div className="privat">   
                <label><a href="/create" className="privatbt">להוסיף-מוצר</a></label>
                <br/><br/><label><a href="/delete" className="privatbt">להסיר-מוצר</a></label>
                <br/><br/><label><a href="/updatename" className="privatbt">עריכת פרטים אישיים</a></label>
        </div>  
        </div> 
  )
}

export default privat